import React from "react";

import full_star from "../../resources/images/helper-misc/full_star.svg";
import grey_star from "../../resources/images/helper-misc/grey_star.svg";
import * as styles from "./StarsGradation.module.css";

export const StarsGradation = ({ grade }) => {
  const base = 5;
  return (
    <div>
      {Array(base)
        .fill()
        .map((_val, i) => (
          <img
            className={styles.stars}
            key={i}
            src={i < grade ? full_star : grey_star}
            alt="stars"
          />
        ))}
    </div>
  );
};
