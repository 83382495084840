// extracted by mini-css-extract-plugin
export var active = "Hero-module--active--e68c9";
export var background = "Hero-module--background--1a3e8";
export var banner = "Hero-module--banner--53ba0";
export var bannerInfo = "Hero-module--bannerInfo--bab57";
export var bannerTitle = "Hero-module--bannerTitle--a4c47";
export var breadcrumb = "Hero-module--breadcrumb--187e0";
export var container = "Hero-module--container--fb932";
export var containerFirst = "Hero-module--containerFirst--c9d17";
export var containerSecond = "Hero-module--containerSecond--7a74c";
export var focusHeadIcons = "Hero-module--focusHeadIcons--21490";
export var infoLines = "Hero-module--infoLines--d25da";
export var information = "Hero-module--information--ff97d";
export var informationButton = "Hero-module--informationButton--27c8a";
export var informationTitle = "Hero-module--informationTitle--15b90";
export var jobs = "Hero-module--jobs--5fcc3";
export var logo = "Hero-module--logo--57597";
export var mainBanner = "Hero-module--mainBanner--e76c8";
export var notActive = "Hero-module--notActive--2e7e4";
export var qualiopi = "Hero-module--qualiopi--2476e";
export var shortDescription = "Hero-module--shortDescription--bcd8e";
export var shortLevel = "Hero-module--shortLevel--d04a5";
export var shortLevelFirst = "Hero-module--shortLevelFirst--f73d7";
export var shortLevelSecond = "Hero-module--shortLevelSecond--d4b0b";
export var sideBanner = "Hero-module--sideBanner--a9a09";
export var sideBannerContent = "Hero-module--sideBannerContent--4ed90";
export var sideBannerText = "Hero-module--sideBannerText--313c3";
export var smallIcone = "Hero-module--smallIcone--dcdce";
export var switchLevel = "Hero-module--switchLevel--f33b3";