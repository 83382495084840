import Slider from "@mui/material/Slider";
import { GatsbyImage } from "gatsby-plugin-image";
import { renderRichText } from "gatsby-source-contentful/rich-text";
import React, { useContext, useState } from "react";
import SwipeableViews from "react-swipeable-views";

import { Arrows } from "../../../../../components/Arrows/Arrows";
import { StarsGradation } from "../../../../../components/StarsGradation/StarsGradation";
import { ZacTitle } from "../../../../../components/ZacTitle/ZacTitle";
import { FIRST_LEVEL } from "../../../../../helpers/constants";
import { FormationFocusContext } from "../../../../../helpers/pagesContext";
import * as styles from "./Notation.module.css";

export const Notation = ({ currentLevel }) => {
  const {
    focusEvaluationBackground,
    focusEvaluationTitle,
    focusEvaluationSubtitle,
    focusEvaluationPeopleIcon,
    focusEvaluationBackgroundNotation,
    focusEvaluationSub,
    hasAdvanced,
    evaluationFormation,
  } = useContext(FormationFocusContext);

  const [value, setValue] = useState(0);

  const [
    evaluationOpinionBase,
    evaluationEndBase,
    evaluationBaseBase,
    evaluationTestimonyBase,
    evaluationNameBase,
  ] =
    currentLevel === FIRST_LEVEL || !hasAdvanced
      ? [
          evaluationFormation.evaluationOpinion,
          evaluationFormation.evaluationEnd,
          evaluationFormation.evaluationBase,
          evaluationFormation.evaluationTestimony,
          evaluationFormation.evaluationName,
        ]
      : [
          evaluationFormation.evaluationOpinionAdvanced,
          evaluationFormation.evaluationEndAdvanced,
          evaluationFormation.evaluationBaseAdvanced,
          evaluationFormation.evaluationTestimonyAdvanced,
          evaluationFormation.evaluationNameAdvanced,
        ];

  const handleChangeIndex = (index) => {
    setTimeout(() => {
      setValue(index);
    }, 250);
  };

  return (
    <section
      className={styles.root}
      style={{
        backgroundImage: `url(${focusEvaluationBackground.file.url})`,
      }}
    >
      <div className={styles.mainTitle}>
        <ZacTitle>
          <h2>
            {focusEvaluationTitle && renderRichText(focusEvaluationTitle)}
          </h2>
        </ZacTitle>
      </div>
      <div className={styles.content}>
        <div className={styles.notation}>
          <div
            className={styles.notationContainer}
            style={{
              backgroundImage: `url(${focusEvaluationBackgroundNotation.file.url})`,
            }}
          >
            <div className={styles.notationHead}>
              <div
                className={styles.notationHeadTitle}
              >{`${focusEvaluationSubtitle} ${evaluationNameBase}`}</div>
              <div className={styles.iconeContainer}>
                <GatsbyImage
                  className={styles.icone}
                  image={focusEvaluationPeopleIcon.gatsbyImageData}
                  alt={focusEvaluationPeopleIcon.file.fileName}
                />
                <div className={styles.avis}>{`${evaluationOpinionBase}`}</div>
              </div>
            </div>
            <div className={styles.notationBody}>
              <div className={styles.notationBodyContent}>
                <div className={styles.notationBodyProgress}>
                  <Slider
                    key={`end-${currentLevel}`}
                    aria-label="progress"
                    defaultValue={evaluationEndBase}
                    color="primary"
                    disabled={true}
                    max={evaluationBaseBase}
                  />
                </div>
                <div
                  className={styles.notationBodyNotation}
                >{`${evaluationEndBase}/${evaluationBaseBase}`}</div>
              </div>
              <div className={styles.notationBodyFooter}>
                {focusEvaluationSub}
              </div>
            </div>
          </div>
        </div>
        <div className={styles.testimony}>
          <SwipeableViews
            index={value}
            onChangeIndex={handleChangeIndex}
            enableMouseEvents
            springConfig={{
              duration: "0.5s",
              easeFunction: "ease-out",
              delay: "0s",
            }}
          >
            {evaluationTestimonyBase?.map(
              (
                {
                  formationName,
                  job,
                  stars,
                  hasStars,
                  testimony,
                  name,
                  id,
                  month,
                },
                i,
              ) => {
                return (
                  <div key={id + currentLevel + i}>
                    <div className={styles.testimonyText}>
                      {testimony && renderRichText(testimony)}
                    </div>
                    <div className={styles.people}>
                      {name} {job}
                    </div>
                    <div className={styles.formation}>
                      <div>{formationName}</div>

                      {hasStars && (
                        <div className={styles.stars}>
                          <StarsGradation grade={stars} />
                        </div>
                      )}
                    </div>
                    <div className={styles.month}>{month}</div>
                  </div>
                );
              },
            )}
          </SwipeableViews>
          <div className={styles.arrows}>
            <Arrows
              value={value}
              setValue={setValue}
              max={evaluationTestimonyBase?.length ?? 0}
            />
          </div>
        </div>
      </div>
    </section>
  );
};
