import React, { useContext, useState } from "react";

import { Footer } from "../../../components/Footer/Footer";
import { OverFooter } from "../../../components/OverFooter/OverFooter";
import { FormationFocusContext } from "../../../helpers/pagesContext";
import { ZAC } from "../../../helpers/route";
import { FormationDetails } from "./components/FormationDetails/FormationDetails";
import { Hero } from "./components/Hero/Hero";
import { Notation } from "./components/Notation/Notation";
import { Presentation } from "./components/Presentation/Presentation";
import { Schedule } from "./components/Schedule/Schedule";

export default function FormationFocus() {
  const { focusFooterBackground, node_locale, footerTitle, footerCta } =
    useContext(FormationFocusContext);

  const getLevel = () => {
    if (typeof window !== "undefined") {
      const search = window.location.search;
      return search.trim() === "" || search.includes("1") ? 1 : 2;
    }
  };

  const changeLevel = (level) => {
    window.history.pushState("", "", window.location.pathname + "?" + level);
    setLevel(level);
  };
  const [level, setLevel] = useState(getLevel());

  return (
    <div>
      <Hero changeLevel={changeLevel} currentLevel={level} />
      <Presentation currentLevel={level} />
      <FormationDetails currentLevel={level} />
      <Schedule currentLevel={level} />
      <Notation currentLevel={level} />
      <OverFooter
        title={footerTitle}
        buttonLink={ZAC}
        buttonLabel={footerCta}
        image={focusFooterBackground.file.url}
        alt={focusFooterBackground.file.name}
      >
        <Footer lang={node_locale} />
      </OverFooter>
    </div>
  );
}
