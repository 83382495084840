import { graphql } from "gatsby";
import React from "react";

import Layout from "../../components/Layout";
import Seo from "../../components/seo";
import { FormationFocusContext } from "../../helpers/pagesContext";
import { FORMATION } from "../../helpers/route";
import FormationFocusScene from "../../scenes/Formation/FormationFocus/FormationFocus";

export default function FormationFocus(props) {
  return (
    <Layout
      navbarTheme="dark"
      currentRoute={FORMATION}
      data={props.data.contentfulMenuLabel}
    >
      <Seo
        title={props.data.contentfulFormation.name}
        description={props.data.contentfulFormation.metaDescription}
      />
      <FormationFocusContext.Provider
        value={{
          ...props.data.contentfulFormation,
          ...props.data.contentfulFormations,
        }}
      >
        <FormationFocusScene />
      </FormationFocusContext.Provider>
    </Layout>
  );
}

export const pageQuery = graphql`
  query MyQuery($url: String!, $locale: String!) {
    contentfulFormation(url: { eq: $url }, node_locale: { eq: $locale }) {
      hasAdvanced
      name
      metaDescription
      hasNb
      hasNbAdvanced
      validationNb
      validationNbAdvanced
      evaluationFormation {
        evaluationName
        evaluationNameAdvanced
        evaluationOpinion
        evaluationOpinionAdvanced
        evaluationBase
        evaluationBaseAdvanced
        evaluationEnd
        evaluationEndAdvanced
        evaluationTestimony {
          id
          formationName
          name
          job
          month
          stars
          hasStars
          title
          testimony {
            raw
          }
        }
        evaluationTestimonyAdvanced {
          id
          formationName
          name
          job
          month
          stars
          hasStars
          title
          testimony {
            raw
          }
        }
      }
      node_locale
      descriptionTitleAdvanced
      descriptionTitle
      informationFirstPart {
        raw
      }
      informationSecondPart
      informationThirdPart {
        raw
      }
      informationFourthPart {
        raw
      }
      heroSmallInfo
      jobs {
        name
      }
      pdf {
        id
        file {
          url
          fileName
        }
      }
      pdfAdvanced {
        id
        file {
          url
          fileName
        }
      }
      url
      shortDescription
      shortDescriptionAdvanced
      heroIllustration {
        file {
          fileName
          url
        }
        gatsbyImageData
      }
      heroIllustrationAdvanced {
        file {
          fileName
          url
        }
        gatsbyImageData
      }
      bulletPoint
      bulletPointAdvanced
      description {
        raw
      }

      logoAdvanced {
        file {
          fileName
          url
        }
        gatsbyImageData
      }
      descriptionJob

      descriptionFullTitle {
        raw
      }
      descriptionImage {
        id
        gatsbyImageData
        file {
          url
          fileName
        }
      }
      descriptionJob
      schedule {
        id
        afternoonModules {
          id
          mainTitle
          finalTest
          steps
          icons {
            id
            title
            gatsbyImageData
            file {
              fileName
              url
            }
          }
        }
        morningModules {
          id
          mainTitle
          finalTest
          steps
          icons {
            id
            title
            gatsbyImageData
            file {
              fileName
              url
            }
          }
        }
      }
      logoAdvanced {
        gatsbyImageData
        file {
          fileName
          url
        }
      }
      jobsAdvanced {
        name
      }
      scheduleAdvanced {
        id
        afternoonModules {
          id
          mainTitle
          finalTest
          steps
          icons {
            id
            title
            gatsbyImageData
            file {
              fileName
              url
            }
          }
        }
        morningModules {
          id
          mainTitle
          finalTest
          steps
          icons {
            id
            title
            gatsbyImageData
            file {
              fileName
              url
            }
          }
        }
      }
      descriptionAdvanced {
        raw
      }
      heroSmallInfoAdvanced
      descriptionJobAdvanced
      descriptionImageAdvanced {
        gatsbyImageData
        file {
          url
          fileName
        }
      }

      descriptionFullTitleAdvanced {
        raw
      }
      informationFirstPartAdvanced {
        raw
      }
      informationSecondPartAdvanced
      informationThirdPartAdvanced {
        raw
      }
      informationFourthPartAdvanced {
        raw
      }
    }
    contentfulFormations(node_locale: { eq: $locale }) {
      node_locale
      level
      returnToFormations
      focusEvaluationAvis
      focusFooterBackground {
        gatsbyImageData
        file {
          url
          fileName
        }
      }
      focusEvaluationBackgroundNotation {
        gatsbyImageData
        file {
          fileName
          url
        }
      }
      focusEvaluationPeopleIcon {
        gatsbyImageData
        file {
          fileName
          url
        }
      }
      focusEvaluationBackground {
        gatsbyImageData
        file {
          url
          fileName
        }
      }
      focusScheduleBackground {
        gatsbyImageData
        file {
          url
          fileName
        }
      }
      downloadLeafletLabel
      formation
      digitalTitle
      vimeoUrl
      focusHeroImage {
        gatsbyImageData
        file {
          fileName
          url
        }
      }
      qualiopi {
        gatsbyImageData
        file {
          fileName
          url
        }
      }
      focusContentAfternoon
      focusContentDay
      focusContentMorning
      focusContentTitle {
        raw
      }
      focusEvaluationStart
      focusAutoEvaluationEnd
      focusEvaluationSub
      focusEvaluationSubtitle
      focusEvaluationTitle {
        raw
      }
      focusHeadCta
      focusHeadDetails
      focusHeadIcons {
        file {
          url
          fileName
        }
        gatsbyImageData
      }
      focusHeadSmallIcons {
        gatsbyImageData
        file {
          url
          fileName
        }
      }
      focusPresentationCtaContact
      footerBackground {
        file {
          url
        }
      }
      footerTitle
      footerCta
    }
    contentfulMenuLabel(node_locale: { eq: $locale }) {
      adventure
      career
      cv
      identity
      innovation
      insideZenity
      inspirations
      node_locale
      recrute
      univers
      contact
      accept
      decline
      cookie
      knowMore
      formation
      ourFormation
      zac
      services
      zenityFactory
    }
  }
`;
