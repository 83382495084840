// extracted by mini-css-extract-plugin
export var arrows = "Notation-module--arrows--c2970";
export var avis = "Notation-module--avis--953e4";
export var content = "Notation-module--content--0b927";
export var formation = "Notation-module--formation--dbae4";
export var icone = "Notation-module--icone--d04fd";
export var iconeContainer = "Notation-module--iconeContainer--46545";
export var mainTitle = "Notation-module--mainTitle--90e7c";
export var month = "Notation-module--month--9ffd5";
export var notation = "Notation-module--notation--fd3d6";
export var notationBody = "Notation-module--notationBody--63149";
export var notationBodyContent = "Notation-module--notationBodyContent--124e7";
export var notationBodyFooter = "Notation-module--notationBodyFooter--78a0e";
export var notationBodyNotation = "Notation-module--notationBodyNotation--134f1";
export var notationBodyProgress = "Notation-module--notationBodyProgress--62964";
export var notationBodyTitle = "Notation-module--notationBodyTitle--af6a7";
export var notationContainer = "Notation-module--notationContainer--ce8a2";
export var notationHead = "Notation-module--notationHead--68ef6";
export var notationHeadTitle = "Notation-module--notationHeadTitle--618ae";
export var people = "Notation-module--people--1c13c";
export var root = "Notation-module--root--49248";
export var stars = "Notation-module--stars--b6bfa";
export var testimony = "Notation-module--testimony--d6446";
export var testimonyText = "Notation-module--testimonyText--fc2be";