import clsx from "clsx";
import { saveAs } from "file-saver";
import { Link } from "gatsby";
import { renderRichText } from "gatsby-source-contentful/rich-text";
import React, { useContext, useState } from "react";

import { BorderButton } from "../../../../../components/Button/Button";
import { SlideTransition } from "../../../../../components/SlideTransition/SlideTransition";
import { FIRST_LEVEL } from "../../../../../helpers/constants";
import { localeLink } from "../../../../../helpers/localeLink";
import { FormationFocusContext } from "../../../../../helpers/pagesContext";
import { FORMATION, FORMATION_CONTACT } from "../../../../../helpers/route";
import add from "../../../../../resources/images/formation-misc/add.svg";
import dot from "../../../../../resources/images/formation-misc/check.svg";
import minus from "../../../../../resources/images/formation-misc/minus.svg";
import * as styles from "./FormationDetails.module.css";

export const FormationDetails = ({ currentLevel }) => {
  const [active, setActive] = useState(false);
  const {
    focusHeadCta,
    focusPresentationCtaContact,
    url,
    descriptionTitleAdvanced,
    descriptionTitle,
    informationFirstPart,
    informationFirstPartAdvanced,
    informationSecondPart,
    informationSecondPartAdvanced,
    informationThirdPart,
    informationThirdPartAdvanced,
    informationFourthPart,
    informationFourthPartAdvanced,
    pdf,
    pdfAdvanced,
    hasAdvanced,
  } = useContext(FormationFocusContext);

  const arr =
    currentLevel === FIRST_LEVEL || !hasAdvanced
      ? [
          informationFirstPart,
          informationSecondPart,
          informationThirdPart,
          informationFourthPart,
        ]
      : [
          informationFirstPartAdvanced,
          informationSecondPartAdvanced,
          informationThirdPartAdvanced,
          informationFourthPartAdvanced,
        ];

  const focusPresentationList =
    currentLevel === FIRST_LEVEL || !hasAdvanced
      ? descriptionTitle
      : descriptionTitleAdvanced;

  const [pdfBase] =
    currentLevel === FIRST_LEVEL || !hasAdvanced ? [pdf] : [pdfAdvanced];

  return (
    <section className={styles.root}>
      <div className={styles.container}>
        {focusPresentationList?.map((data, i) => (
          <SlideTransition key={i} thresholds={100} delay={0.2 + i * 0.1}>
            <div
              className={clsx(
                active === i && styles.active,
                styles.dataContainer,
              )}
            >
              <div
                className={styles.title}
                onClick={() => {
                  setActive(active === i ? false : i);
                }}
              >
                <h3>{data}</h3>
                <img
                  className={styles.titleCTA}
                  src={active === i ? minus : add}
                  alt="cta"
                />
              </div>
              <div className={styles.data}>
                {i !== 1 ? (
                  arr[i] && renderRichText(arr[i])
                ) : (
                  <ul className={styles.dataList}>
                    {arr[i]?.map((data, i) => (
                      <li key={i}>
                        <img src={dot} alt="dot" />
                        <p>{data}</p>
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            </div>
          </SlideTransition>
        ))}
      </div>
      <div className={styles.cta}>
        <div className={styles.informationButton}>
          <BorderButton
            label={focusHeadCta}
            responsive={false}
            width={0}
            color="#3FC3CC"
            wide={true}
            onClick={() => {
              saveAs(pdfBase.file.url, pdfBase.file.fileName);
            }}
          />
        </div>
        <Link
          to={localeLink(FORMATION_CONTACT)}
          state={{ prevPath: `${FORMATION}/${url}?${currentLevel}` }}
        >
          <div className={styles.informationButton}>
            <BorderButton
              label={focusPresentationCtaContact}
              responsive={false}
              width={0}
              color="#fff"
              background="#3FC3CC"
              wide={true}
            />
          </div>
        </Link>
      </div>
    </section>
  );
};
