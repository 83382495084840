import { GatsbyImage } from "gatsby-plugin-image";
import { renderRichText } from "gatsby-source-contentful/rich-text";
import React, { useContext } from "react";

import { ZacTitle } from "../../../../../components/ZacTitle/ZacTitle";
import { FIRST_LEVEL } from "../../../../../helpers/constants";
import { FormationFocusContext } from "../../../../../helpers/pagesContext";
import * as styles from "./Presentation.module.css";

export const Presentation = ({ currentLevel }) => {
  const {
    descriptionFullTitle,
    descriptionFullTitleAdvanced,
    description,
    descriptionAdvanced,
    descriptionImage,
    descriptionImageAdvanced,
    descriptionJob,
    descriptionJobAdvanced,
    hasAdvanced,
  } = useContext(FormationFocusContext);

  const [
    descriptionFullTitleBase,
    descriptionBase,
    descriptionImageBase,
    descriptionJobBase,
  ] =
    currentLevel === FIRST_LEVEL || !hasAdvanced
      ? [descriptionFullTitle, description, descriptionImage, descriptionJob]
      : [
          descriptionFullTitleAdvanced,
          descriptionAdvanced,
          descriptionImageAdvanced,
          descriptionJobAdvanced,
        ];

  return (
    <section className={styles.root}>
      <div className={styles.container}>
        <div className={styles.descriptionFullTitle}>
          <ZacTitle>
            <h2>
              {descriptionFullTitleBase &&
                renderRichText(descriptionFullTitleBase)}
            </h2>
          </ZacTitle>
        </div>
        <div className={styles.descriptionData}>
          <div>
            <GatsbyImage
              className={styles.descriptionImage}
              image={descriptionImageBase.gatsbyImageData}
              alt={descriptionImageBase.file.fileName}
            />
            <div className={styles.descriptionJob}>{descriptionJobBase}</div>
          </div>
          <div className={styles.description}>
            {descriptionBase && renderRichText(descriptionBase)}
          </div>
        </div>
      </div>
    </section>
  );
};
