import clsx from "clsx";
import { GatsbyImage } from "gatsby-plugin-image";
import { renderRichText } from "gatsby-source-contentful/rich-text";
import React, { useContext, useState } from "react";

import { SlideTransition } from "../../../../../components/SlideTransition/SlideTransition";
import { ZacTitle } from "../../../../../components/ZacTitle/ZacTitle";
import { FIRST_LEVEL } from "../../../../../helpers/constants";
import { FormationFocusContext } from "../../../../../helpers/pagesContext";
import add from "../../../../../resources/images/formation-misc/add.svg";
import check from "../../../../../resources/images/formation-misc/check.svg";
import checkGold from "../../../../../resources/images/formation-misc/check_gold.svg";
import minus from "../../../../../resources/images/formation-misc/minus.svg";
import * as styles from "./Schedule.module.css";

export const Schedule = ({ currentLevel }) => {
  const {
    focusContentTitle,
    focusContentDay,
    focusContentMorning,
    focusContentAfternoon,
    schedule,
    scheduleAdvanced,
    hasAdvanced,
    hasNb,
    hasNbAdvanced,
    validationNb,
    validationNbAdvanced,
  } = useContext(FormationFocusContext);
  const [active, setActive] = useState([]);

  const scheduleBase =
    currentLevel === FIRST_LEVEL || !hasAdvanced ? schedule : scheduleAdvanced;

  return (
    <section className={styles.root}>
      <div className={styles.focusContentTitle}>
        <ZacTitle>
          <h2>{focusContentTitle && renderRichText(focusContentTitle)}</h2>
        </ZacTitle>
      </div>
      {scheduleBase.map((day, i) => {
        return (
          <SlideTransition key={i} thresholds={100} delay={0.2 + i * 0.3}>
            <div className={styles.day} key={day.id}>
              <div
                className={styles.title}
                onClick={() => {
                  const arr = active.includes(i)
                    ? active.filter((data) => data !== i)
                    : [...active, i];

                  setActive(arr);
                }}
              >
                {`${focusContentDay} ${i + 1}`}
                <img
                  className={styles.titleCTA}
                  src={active.includes(i) ? minus : add}
                  alt="cta"
                />
              </div>
              <div
                className={clsx(
                  styles.content,
                  active.includes(i) && styles.active,
                )}
              >
                <div className={styles.partModules}>
                  <div className={styles.sideDay}>{focusContentMorning}</div>
                  <div className={styles.mainDayContent}>
                    {day.morningModules?.map((data) => {
                      return (
                        <div
                          key={data.id}
                          className={clsx(
                            styles.dayContent,
                            data.finalTest && styles.finalTestContent,
                          )}
                        >
                          <div className={styles.dayContentTitle}>
                            <img
                              className={styles.check}
                              src={data.finalTest ? checkGold : check}
                              alt="check"
                            />
                            <div>{data.mainTitle}</div>
                          </div>
                          <div className={styles.dayContentInfo}>
                            <div className={styles.steps}>
                              {data.steps && data.steps.length && (
                                <ul>
                                  {data.steps.map((step, i) => {
                                    return <li key={i + step}>{step}</li>;
                                  })}
                                </ul>
                              )}
                            </div>
                            <div
                              className={clsx(
                                styles.icones,
                                data.finalTest && styles.finalTest,
                              )}
                            >
                              {data.icons &&
                                data.icons.map((icone) => {
                                  return (
                                    <div
                                      key={icone.id}
                                      className={styles.iconeContainer}
                                    >
                                      <GatsbyImage
                                        className={styles.icone}
                                        image={icone.gatsbyImageData}
                                        alt={icone.file.fileName}
                                      />
                                      <div className={styles.iconeTitle}>
                                        {icone.title}
                                      </div>
                                    </div>
                                  );
                                })}
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
                <div className={styles.partModules}>
                  <div className={styles.sideDay}>{focusContentAfternoon}</div>
                  <div className={styles.mainDayContent}>
                    {day.afternoonModules?.map((data) => {
                      return (
                        <div key={data.id}>
                          <div
                            className={clsx(
                              styles.dayContent,
                              data.finalTest && styles.finalTestContent,
                            )}
                          >
                            <div className={styles.dayContentTitle}>
                              <img
                                className={styles.check}
                                src={data.finalTest ? checkGold : check}
                                alt="check"
                              />
                              <div>{data.mainTitle}</div>
                            </div>
                            <div className={styles.dayContentInfo}>
                              {data.steps && data.steps.length && (
                                <div className={styles.steps}>
                                  <ul>
                                    {data.steps &&
                                      data.steps.map((step, i) => {
                                        return <li key={i + step}>{step}</li>;
                                      })}
                                  </ul>
                                </div>
                              )}
                              <div
                                className={clsx(
                                  styles.icones,
                                  data.finalTest && styles.finalTest,
                                )}
                              >
                                {data.icons &&
                                  data.icons.map((icone) => {
                                    return (
                                      <div
                                        key={icone.id}
                                        className={styles.iconeContainer}
                                      >
                                        <GatsbyImage
                                          className={styles.icone}
                                          image={icone.gatsbyImageData}
                                          alt={icone.file.fileName}
                                        />
                                        <div className={styles.iconeTitle}>
                                          {icone.title}
                                        </div>
                                      </div>
                                    );
                                  })}
                              </div>
                            </div>
                          </div>
                          {data.finalTest && (
                            <div className={styles.nb}>
                              {currentLevel === FIRST_LEVEL || !hasAdvanced ? (
                                <div>{hasNb && validationNb}</div>
                              ) : (
                                <div>
                                  {hasNbAdvanced && validationNbAdvanced}
                                </div>
                              )}
                            </div>
                          )}
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </SlideTransition>
        );
      })}
    </section>
  );
};
