import clsx from "clsx";
import { saveAs } from "file-saver";
import { AnchorLink } from "gatsby-plugin-anchor-links";
import { GatsbyImage } from "gatsby-plugin-image";
import React, { useContext, useEffect, useState } from "react";

import { BorderButton } from "../../../../../components/Button/Button";
import { SlideTransition } from "../../../../../components/SlideTransition/SlideTransition";
import { FIRST_LEVEL, SECOND_LEVEL } from "../../../../../helpers/constants";
import { localeLink } from "../../../../../helpers/localeLink";
import { FormationFocusContext } from "../../../../../helpers/pagesContext";
import { FORMATION } from "../../../../../helpers/route";
import arrow from "../../../../../resources/images/opportunity-misc/arrow.svg";
import * as styles from "./Hero.module.css";

export const Hero = ({ currentLevel, changeLevel }) => {
  const [curLevel, setCurLevel] = useState(false);

  useEffect(() => {
    setCurLevel(currentLevel);
  }, [currentLevel]);

  const {
    focusHeadIcons,
    focusHeadDetails,
    focusHeadSmallIcons,
    level,
    returnToFormations,
    focusHeadCta,
    hasAdvanced,
    shortDescription,
    shortDescriptionAdvanced,
    heroIllustration,
    heroIllustrationAdvanced,
    logoAdvanced,
    pdf,
    pdfAdvanced,
    bulletPoint,
    bulletPointAdvanced,
    jobs,
    jobsAdvanced,
    heroSmallInfo,
    heroSmallInfoAdvanced,
  } = useContext(FormationFocusContext);

  const [pdfBase, bulletPointBase, jobsBase, heroSmallInfoBase] =
    curLevel === FIRST_LEVEL || !hasAdvanced
      ? [pdf, bulletPoint, jobs, heroSmallInfo]
      : [pdfAdvanced, bulletPointAdvanced, jobsAdvanced, heroSmallInfoAdvanced];

  return (
    <section>
      <div
        className={clsx(
          styles.container,
          curLevel === FIRST_LEVEL || !hasAdvanced
            ? styles.containerFirst
            : styles.containerSecond,
        )}
      >
        <div className={styles.breadcrumb}>
          <AnchorLink to={localeLink(FORMATION) + "#filter"}>
            <img src={arrow} alt="ping" />
            <span>{returnToFormations}</span>
          </AnchorLink>
        </div>
        {hasAdvanced && (
          <div className={styles.switchLevel}>
            <div
              className={clsx(
                curLevel === FIRST_LEVEL || !hasAdvanced
                  ? styles.active
                  : styles.notActive,
              )}
              onClick={() => changeLevel(FIRST_LEVEL)}
            >
              {level} I
            </div>
            {hasAdvanced && (
              <div
                className={clsx(
                  curLevel === SECOND_LEVEL ? styles.active : styles.notActive,
                )}
                onClick={() => changeLevel(SECOND_LEVEL)}
              >
                {level} II
              </div>
            )}
          </div>
        )}
        <div className={styles.information}>
          <div className={styles.informationTitle}>
            <img
              className={styles.logo}
              src={logoAdvanced.file.url}
              alt={logoAdvanced.filename}
            />
            <div>
              <div className={styles.shortDescription}>
                {hasAdvanced && (
                  <div
                    className={clsx(
                      styles.shortLevel,
                      curLevel === SECOND_LEVEL
                        ? styles.shortLevelSecond
                        : styles.shortLevelFirst,
                    )}
                  >
                    {`${level} ${curLevel === SECOND_LEVEL ? "II" : "I"}`}
                  </div>
                )}
                <SlideTransition thresholds={200} delay={0.2}>
                  <h1>
                    {curLevel === SECOND_LEVEL
                      ? shortDescriptionAdvanced
                      : shortDescription}
                  </h1>
                  <div className={styles.informationButton}>
                    <BorderButton
                      label={focusHeadCta}
                      responsive={false}
                      width={0}
                      color="#fff"
                      wide={true}
                      onClick={() => {
                        saveAs(pdfBase.file.url, pdfBase.file.fileName);
                      }}
                    />
                  </div>
                </SlideTransition>
              </div>
            </div>
          </div>
        </div>
        <GatsbyImage
          className={styles.background}
          image={
            curLevel === SECOND_LEVEL
              ? heroIllustrationAdvanced.gatsbyImageData
              : heroIllustration.gatsbyImageData
          }
          alt={
            curLevel === SECOND_LEVEL
              ? heroIllustrationAdvanced.file.fileName
              : heroIllustration.file.fileName
          }
        />
      </div>
      <div className={styles.banner}>
        <div className={styles.mainBanner}>
          {focusHeadIcons.map((icone, i) => (
            <div key={i}>
              <GatsbyImage
                key={i}
                className={styles.focusHeadIcons}
                image={icone.gatsbyImageData}
                alt={icone.file.fileName}
              />
              <div className={styles.bannerTitle}>{focusHeadDetails[i]}</div>
              <div className={styles.bannerInfo}>
                {i === focusHeadIcons.length - 1
                  ? jobsBase.map((job, i) => {
                      return (
                        <div key={i} className={styles.jobs}>
                          {job.name[0].toUpperCase() + job.name.substring(1)}
                        </div>
                      );
                    })
                  : bulletPointBase?.[i].split("|").map((txt, i) => (
                      <p className={styles.infoLines} key={i}>
                        {txt}
                      </p>
                    ))}
              </div>
            </div>
          ))}
          <div className={styles.sideBanner}>
            <div className={styles.sideBannerContent}>
              {focusHeadSmallIcons.map((data, i) => {
                return (
                  <div key={i}>
                    <img className={styles.smallIcone} src={data.file.url} />
                    <div className={styles.sideBannerText}>
                      {heroSmallInfoBase?.[i]}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
