// extracted by mini-css-extract-plugin
export var container = "Presentation-module--container--73deb";
export var description = "Presentation-module--description--6a182";
export var descriptionData = "Presentation-module--descriptionData--fb1c2";
export var descriptionFullTitle = "Presentation-module--descriptionFullTitle--07ea4";
export var descriptionImage = "Presentation-module--descriptionImage--0be95";
export var descriptionJob = "Presentation-module--descriptionJob--2b92f";
export var image = "Presentation-module--image--80071";
export var imageContainer = "Presentation-module--imageContainer--9aa1f";
export var infoText = "Presentation-module--infoText--05523";
export var root = "Presentation-module--root--52e11";
export var vector = "Presentation-module--vector--5dd80";