// extracted by mini-css-extract-plugin
export var active = "FormationDetails-module--active--31508";
export var container = "FormationDetails-module--container--5f9ae";
export var cta = "FormationDetails-module--cta--37958";
export var data = "FormationDetails-module--data--b908a";
export var dataContainer = "FormationDetails-module--dataContainer--6431d";
export var dataList = "FormationDetails-module--dataList--0b3d2";
export var informationButton = "FormationDetails-module--informationButton--ded00";
export var root = "FormationDetails-module--root--1fec0";
export var title = "FormationDetails-module--title--f5177";
export var titleCTA = "FormationDetails-module--titleCTA--13660";